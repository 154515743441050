import axios from 'axios';

const host = location.host;
let url = location.origin;

if (host.indexOf('192.168') > -1 || host.indexOf('127.0.0.1') > -1 || host.indexOf('localHost') > -1) {
  // url = 'http://192.168.1.111:9501'; //富哥本地
  // url = 'https://redbag-service.pre.hainanxingyue.cn'; //预发布环境
  // url = 'https://redbag-service.hainanxingyue.cn'; //线上环境
  url = 'https://redbag.test.rpgmoba.com'; //测试环境
  // url = '/mydaili';
  // url = 'https://idk.hainanxingyue.cn';
}

if (host.indexOf('.rpgmoba.') > -1) {
  url = 'https://redbag-service.hainanxingyue.cn';
  if (host.indexOf('.test.') > -1) {
    url = 'https://redbag.test.rpgmoba.com';
  }
  if (host.indexOf('.pre.') > -1) {
    url = 'https://redbag-service.hainanxingyue.cn';
  }
}

if (host.indexOf('.hainanxingyue.') > -1) {
  url = 'https://redbag-service.hainanxingyue.cn';
  if (host.indexOf('.test.') > -1) {
    url = 'https://redbag-service.test.hainanxingyue.cn';
  }
  if (host.indexOf('.pre.') > -1) {
    url = 'https://redbag-service.pre.hainanxingyue.cn';
  }
}

let config = {
  baseURL: url,
  tiemout: 10 * 1000,
};

const _axios = axios.create(config);

// 请求拦截
_axios.interceptors.request.use(
  (config) => {
    // config.headers.common["Authorization"] = "Bearer " + window.sessionStorage.getItem("ato");
    // config.headers.common['Content-type'] = 'application/json';
    // config.headers.common['Content-type'] = 'application/x-www-form-urlencoded';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default _axios;
