import md5 from 'js-md5';
import Qs from 'qs';

// 签名
export function buildParams(params) {
  let data = {};
  Object.keys(params)
    .sort()
    .map((key) => {
      data[key] = params[key];
    });
  // data.appKey = "ffb47fa4d0dd55f49146c6d7f42b6125"
  // data.key = "ffb47fa4d0dd55f49146c6d7f42b6125"
  data.key = window.sessionStorage.getItem('appKey');
  params['sign'] = md5(Qs.stringify(data));
  return params;
}
